import Home from '../views/Home.vue'
import About from '../views/About.vue'

const ROUTE_HOME = {
    path: '/',
    name: 'Home',
    component: Home
};
const ROUTE_ABOUT = {
    path: '/about',
    name: 'About',
    component: About
};
const ROUTE_ACTU = {
    path: '/actu',
    name: 'Actu',
    component: () => import(/* webpackChunkName: "actu" */ '../views/Actu.vue'),
};
const ROUTE_GALERIE = {
    path: '/galerie',
    name: 'Galerie',
    component: () => import(/* webpackChunkName: "actu" */ '../views/Galerie.vue'),
};

const ROUTE_CONTACT = {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "actu" */ '../views/Contact.vue'),
};

const ROUTE_INFOS = {
    path: '/infos',
    name: 'Infos',
    component: () => import(/* webpackChunkName: "infos" */ '../views/Infos-pratiques.vue'),
};
const ROUTE_PARTENAIRE = {
    path: '/partenaire',
    name: 'Partenaire',
    component: () => import(/* webpackChunkName: "partenaire" */ '../views/Partenaire.vue'),
};
const ROUTE_PARTENAIRE_ONE = {
    path: '/single-partenaire/:partenaireId',
    name: 'Single-partenaire',
    props: true,
    component: () => import(/* webpackChunkName: "single-partenaire" */ '../components/Partenaire/Single-partenaire.vue'),
};
const ROUTE_BENEVOLE = {
    path: '/benevole',
    name: 'Benevole',
    component: () => import(/* webpackChunkName: "benevole" */ '../components/Participer/Benevole.vue'),
};
const ROUTE_ARTICLE = {
    path: '/article/:articleId',
    name: 'Article',
    props: true,
    component: () => import(/* webpackChunkName: "article" */ '../views/article/Article.vue'),
};

const ROUTE_ANIMATIONS = {
    path: '/animation',
    name: 'Animations',
    component: () => import(/* webpackChunkName: "animation" */ '../components/Programme/Animations.vue'),
};
const ROUTE_EXPOSANTS = {
    path: '/exposant',
    name: 'Exposants',
    component: () => import(/* webpackChunkName: "exposant" */ '../components/Programme/Exposants.vue'),
};

const ROUTE_INTERVENANT_ONE = {
    path: '/single-intervenant/:intervenantId',
    name: 'Single-intervenant',
    props: true,
    component: () => import(/* webpackChunkName: "single-intervenant" */ '../components/Programme/Intervenant/Single-intervenant.vue'),
};


const ROUTE_INVITES = {
    path: '/invite',
    name: 'Invités',
    component: () => import(/* webpackChunkName: "invite" */ '../components/Programme/Invites.vue'),
};
const ROUTE_CREATEUR = {
    path: '/createur',
    name: 'Createur',
    component: () => import(/* webpackChunkName: "createur" */ '../components/Participer/Createur.vue'),
};
const ROUTE_COSPLAY = {
    path: '/cosplay',
    name: 'Cosplay',
    component: () => import(/* webpackChunkName: "cosplay" */ '../components/Participer/Cosplay.vue'),
};
const ROUTE_INSCRIPTION_EXPOSANT = {
    path: '/inscription-exposant',
    name: 'InscriptionExposant',
    component: () => import(/* webpackChunkName: "cosplay" */ '../components/Participer/DevenirExposant.vue'),
};
const EVENT_ROUTES = [
    ROUTE_ANIMATIONS,
    ROUTE_EXPOSANTS,
    ROUTE_INVITES,
    ROUTE_CREATEUR,
    ROUTE_INSCRIPTION_EXPOSANT,
    ROUTE_COSPLAY,
];

const ROUTE_BILLETERIE = {
    path: '/billeterie',
    name: 'Billetterie',
    component: () => import(/* webpackChunkName: "billeterie" */ '../views/Billeterie.vue'),
};
const TICKETINT_ROUTES = [
    ROUTE_BILLETERIE
];

const BASIC_ROUTES = [
    ROUTE_HOME,
    ROUTE_ACTU,
    ROUTE_ABOUT,
    ROUTE_INFOS,
    ROUTE_PARTENAIRE,
    ROUTE_PARTENAIRE_ONE,
    ROUTE_INTERVENANT_ONE,
    ROUTE_BENEVOLE,
    ROUTE_ARTICLE,
    ROUTE_GALERIE,
    ROUTE_CONTACT,
];

export const initRoutes = ({
                               currentEvent = false,
                               ticketing = false
                           }) => {
    return BASIC_ROUTES
        .concat(currentEvent ? EVENT_ROUTES : [])
        .concat(ticketing ? TICKETINT_ROUTES : []);
};

export const initMenuItems = ({
                                  currentEvent = false,
                                  ticketing = false
                              }) => {
    return ([
        {
            name: ROUTE_ACTU.name,
            label: "Actualité"
        },
    ])
        .concat(currentEvent ? [ // Programme
            {
                name: "Programme",
                label: "Programme",
                items: [
                    {name: ROUTE_ANIMATIONS.name, label: "Animations"},
                    {name: ROUTE_EXPOSANTS.name, label: "Exposants"},
                    {name: ROUTE_INVITES.name, label: "Invités"},
                ]
            }
        ] : [])
        .concat(currentEvent ? [ // Programme
            {
                name: "Participer",
                label: "Participer",
                items: [
                    {name: ROUTE_BENEVOLE.name, label: "Devenir bénévole"},
                    {name: ROUTE_COSPLAY.name, label: "Concours cosplay"},
                    {name: ROUTE_CREATEUR.name, label: "Concours créateur"},
                    {name: ROUTE_INSCRIPTION_EXPOSANT.name, label: "Devenir exposant"}
                ]
            }
        ] : [])
        .concat(ticketing ? [
            {
                name: ROUTE_BILLETERIE.name,
                label: "Billetterie"
            }
        ] : [])
        .concat([
            {
                name: ROUTE_GALERIE.name,
                label: "Galerie"
            },
            {
                name: ROUTE_PARTENAIRE.name,
                label: "Partenaire"
            },
            {
                name: ROUTE_INFOS.name,
                label: "Infos pratiques"
            },
            {
                name: ROUTE_CONTACT.name,
                label: "Contact"
            }
        ]);
};
